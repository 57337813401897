@import '../../../styles/mixin';

.Contents {
    display: flex;
    justify-content: center;
}
.List {
    @include pc {
        width: 600px;
    }
    @include sp {
        width: calc(100vw - 20px);
    }
}