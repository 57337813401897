.Row {
    display: flex;
    gap: 5px;

    align-items: center;
}

.BtnArea {
    position: relative;
    min-width: 80px;
}
.Progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
}