$primary: #17a2b8;
$secondary: rgb(145, 120, 82); //#ddd;
$black: hsl(0, 0%, 27%);
$gray: #ccc;

:export {
    primaryColor: $primary;
    secondaryColor: $secondary;
    textColor: $black;
}
