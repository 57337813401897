.Container {
    height: 100%;
    overflow: hidden;
}
$map-height: 300px;
.Map {
    width: 100%;
    height: $map-height;
}
.Contents {
    height: calc(100% - $map-height);
    overflow: auto;
}
.NewBtnArea {
    position: absolute;
    bottom: 15px;
    right: 15px;

    button {
        background-color: #fff;
    }
}