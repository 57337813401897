// reffer: https://web-dev.tech/front-end/sass/mixin-for-media-query/
@mixin pc {
  @media screen and (orientation: landscape) {
    @content;
  }
}

@mixin sp {
  @media screen and (orientation: portrait) {
    @content;
  }
}

// 縦長SP (iPhone12等）
@mixin spl {
  @media screen and (orientation: portrait) and (min-height: 700px) {
    @content;
  }
}
