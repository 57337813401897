@import '../../styles/mixin';

.Container {
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    // justify-content: center;

    @include pc {
        width: calc(100% - 100px);
        // max-width: 800px;
    }

    @include sp {
        width: calc(100% - 10px);
    }
}