@import '../../../styles/global';

.Form {
    display: flex;
    flex-direction: column;

    gap: 5px;
}

.CategoryLabel {
    display: flex;
    border-bottom: 1px solid $gray;
    > span {
        width: 5rem;
        font-weight: 400;
        font-size: .75rem;
    }
}