.Contents {
    padding: 10px;
    box-sizing: border-box;
}
.ProgressArea {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(#aaa, .5);
    z-index: 1000;
}